import React from 'react';
import styled, { css } from 'styled-components';
// import WalletIcon from '../../assets/images/WalletIcon.inline.svg';
import { AccentedLinkBig } from '../../components/atoms/AccentedLink';
import { ExternalLink } from '../../components/atoms/Link';
import {
  Header1,
  Header2,
  TextLarge,
} from '../../components/atoms/Typography/Typography';
import { MainContentColumnWrapper } from '../../components/layout/Layout';
// import useMaxWidthBreakpoint from '../../hooks/useMaxWidthBreakpoint';
import { forScreenAtMost680pxWide } from '../../styles/mediaQueries';

export interface OpenPosition {
  id: string;
  title: string;
  employmentTypeCode: string;
  slug: string;
  careersUrl: string;
  careersApplyUrl: string;

  // remuneration: {
  //   min: number;
  //   max: number;
  // };
}

interface OpenPositionsProps {
  openPositions: OpenPosition[];
}

const OpenPositions = ({ openPositions }: OpenPositionsProps): JSX.Element => {
  return (
    <SectionWrapper>
      <MainContentColumnWrapper>
        <SectionHeader $color="secondary" id="open-positions">
          Open Positions
        </SectionHeader>
        <OpenPositionsList>
          {openPositions.map(
            ({
              id,
              title,
              employmentTypeCode,
              careersUrl,
              careersApplyUrl,
            }) => (
              <OpenPositionWrapper key={id}>
                <Header2 $color="secondary">
                  <ExternalLink href={careersUrl}>{title}</ExternalLink>
                </Header2>
                <TextLarge $color="delicateAccented">
                  {employmentTypeCode == 'fulltime' ? 'Full-time' : 'Part-time'}
                </TextLarge>
                <Remuneration $color="secondary">
                  {/* <StyledWalletIcon />{' '}
                {[remuneration.min, remuneration.max].join(' - ')} PLN */}
                </Remuneration>

                <ApplyNow $color="secondary">
                  <ExternalLink href={careersApplyUrl}>Apply Now</ExternalLink>
                </ApplyNow>
              </OpenPositionWrapper>
            ),
          )}
        </OpenPositionsList>
      </MainContentColumnWrapper>
    </SectionWrapper>
  );
};

export default OpenPositions;

const SectionHeader = styled(Header1)`
  text-align: center;
`;

const SectionWrapper = styled.section`
  padding: 100px 0 0 0;
  background: ${({ theme }) => theme.colors.backgrounds.secondaryAccented};
  overflow: hidden;
  color: ${({ theme }) => theme.colors.texts.secondary}}
  min-height: 500px;

  text-align: center;
  display: flex;
  margin: 0 auto 200px;

  ${forScreenAtMost680pxWide(css`
    padding-top: 120px;
  `)}
`;

const OpenPositionsList = styled.ul`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  text-align: left;
  padding: 0;
  margin-bottom: 200px;
`;

const Remuneration = styled(TextLarge)`
  display: flex;
  align-items: center;
  margin-top: 34px;
`;

const ApplyNow = styled(AccentedLinkBig)`
  margin-top: 86px;
`;

const OpenPositionWrapper = styled.li`
  width: 358px;
  height: 358px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 auto;
  margin-top: 30px;
  padding: 30px;
  background: ${({ theme }) => theme.colors.backgrounds.secondary};
  box-shadow: 0px 100px 80px rgba(9, 16, 24, 0.07),
    0px 64.8148px 46.8519px rgba(9, 16, 24, 0.0531481),
    0px 38.5185px 25.4815px rgba(9, 16, 24, 0.0425185),
    0px 20px 13px rgba(9, 16, 24, 0.035),
    0px 8.14815px 6.51852px rgba(9, 16, 24, 0.0274815),
    0px 1.85185px 3.14815px rgba(9, 16, 24, 0.0168519);
`;

// const StyledWalletIcon = styled(WalletIcon)`
//   margin-right: 10px;
// `;
