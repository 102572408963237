import React from 'react';
import styled, { css } from 'styled-components';
import {
  GradientHeader,
  GradientHeader1,
  Header3,
  TextMedium,
} from '../../components/atoms/Typography/Typography';
import { MainColumnWrapper } from '../../components/layout/Layout';

import orbits from '../../assets/images/orbits.svg';
import board from '../../assets/images/careers/photoTeam.png';
import companydev from '../../assets/images/careers/icons/companydev.svg';
import holidays from '../../assets/images/careers/icons/holidays.svg';
import insurance from '../../assets/images/careers/icons/insurance.svg';
import knowledge from '../../assets/images/careers/icons/knowledge.svg';
import multisport from '../../assets/images/careers/icons/multisport.svg';
import partners from '../../assets/images/careers/icons/partners.svg';
import stethoscope from '../../assets/images/careers/icons/stethoscope.svg';
import topprojects from '../../assets/images/careers/icons/topprojects.svg';
import { forScreenAtMost680pxWide } from '../../styles/mediaQueries';

const perks = [
  {
    icon: partners,
    title: 'Cooperation with top partners',
    contents:
      'Cooperation with top global experts and recognized business partners',
  },
  {
    icon: topprojects,
    title: 'Top projects',
    contents: 'Opportunity to work on a game changing project in the field',
  },
  {
    icon: companydev,
    title: 'Company development',
    contents: 'Direct impact on the product and company development',
  },
  {
    icon: knowledge,
    title: 'Get unique knowledge',
    contents:
      'Access to unique knowledge across bioinformatics and machine learning',
  },
  {
    icon: stethoscope,
    title: 'Medical care',
    contents: 'Private medical care package (Medicover)',
  },
  {
    icon: multisport,
    title: 'Multisport card',
    contents: 'Enjoy sports facilities with Multisport card',
  },
  {
    icon: insurance,
    title: 'Private insurance',
    contents: '',
  },
  {
    icon: holidays,
    title: 'Paid holidays',
    contents: '26 days of annual paid leave',
  },
];

const MainSection = (): JSX.Element => {
  return (
    <MainSectionWrapper>
      <MainColumnWrapper>
        <Header $gradient="secondary">
          What it’s like to be part of NaturalAntibody
        </Header>
        <DescriptionWrapper>
          <BoardImg src={board} />
          <TeamAbout $color="delicateStronger">
            We&apos;re a team of experts on a mission to increase the speed and
            efficiency of drug development with the help of computational
            methods. NaturalAntibody promotes this approach to engineering
            antibody-based therapeutics by equipping scientists with analytics
            solutions based on the latest technologies like AI and machine
            learning. Join us to expand your skill set, work alongside top minds
            in the field, and transform the way drugs are developed and brought
            to market
          </TeamAbout>
        </DescriptionWrapper>

        <PerksSectionWrapper>
          <PerksHeader $gradient="secondary">Perks and benefits</PerksHeader>
          <PerksList>
            {perks.map(({ icon, title, contents }) => (
              <Perk key={title}>
                <PerkIcon src={icon} />
                <Header3 $color="secondary">{title}</Header3>
                <PerkText $color="delicateStronger">{contents}</PerkText>
              </Perk>
            ))}
          </PerksList>
        </PerksSectionWrapper>
      </MainColumnWrapper>
    </MainSectionWrapper>
  );
};

const BoardImg = styled.img`
  width: 420px;
  max-width: 90%;
  height: 320px;
  box-shadow: 0px 100px 80px rgba(9, 16, 24, 0.07),
    0px 64.8148px 46.8519px rgba(9, 16, 24, 0.0531481),
    0px 38.5185px 25.4815px rgba(9, 16, 24, 0.0425185),
    0px 20px 13px rgba(9, 16, 24, 0.035),
    0px 8.14815px 6.51852px rgba(9, 16, 24, 0.0274815),
    0px 1.85185px 3.14815px rgba(9, 16, 24, 0.0168519);
`;

const TeamAbout = styled(TextMedium)`
  width: 422px;
  padding: 30px;
`;

const Header = styled(GradientHeader1)`
  margin: 0 250px 0 auto;

  ${forScreenAtMost680pxWide(css`
    margin: 0 auto;
    text-align: center;

    :before {
      width: 100%;
    }
  `)}

  max-width: 454px;
  position: relative;

  :after {
    content: '';
    z-index: ${({ theme }) => theme.layers.base - 1};

    position: absolute;
    top: 15px;
    left: 0;

    transform: translate(-50%, -50%);

    width: 409px;
    height: 409px;

    background: url('${orbits}');
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
`;

const MainSectionWrapper = styled.section`
  background: ${({ theme }) => theme.colors.backgrounds.secondary};
  min-height: 500px;
  padding-top: 174px;
  position: relative;
  z-index: ${({ theme }) => theme.layers.base};
`;

const PerksSectionWrapper = styled.section`
  text-align: center;
`;

const PerksHeader = styled(GradientHeader)`
  text-align: center;
  margin: 125px auto;
`;

const PerksList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0;
  padding: 10px;
`;

const PerkIcon = styled.img`
  width: 64px;
  height: 64px;
  margin: 75px;
  align-self: center;
  /* border: 1px solid black; */
  /* flex: 1; */
`;

const Perk = styled.li`
  width: 358px;
  height: 358px;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;

  position: relative;

  :before {
    content: '';
    z-index: ${({ theme }) => theme.layers.base - 1};

    position: absolute;
    top: -25px;
    left: -25px;

    /* transform: translate(-50%, -50%); */
    transform: scale(90%, 90%);

    width: 358px;
    height: 358px;

    background: url('${orbits}');
  }
`;

const PerkText = styled(TextMedium)`
  max-width: 259px;
  height: 50px;
  text-align: center;
`;

export default MainSection;
