import React from 'react';
import styled, { css } from 'styled-components';
import careersBackground from '../../assets/images/careersBackground.png';
import careersMobileBackground from '../../assets/images/careersMobileBackground.jpg';
import ScrollLink from '../../components/atoms/ScrollLink';
import {
  TextMedium,
  Title,
  Upheader,
} from '../../components/atoms/Typography/Typography';
import { MainContentColumnWrapper } from '../../components/layout/Layout';
import useMaxWidthBreakpoint from '../../hooks/useMaxWidthBreakpoint';
import { forScreenAtMost680pxWide } from '../../styles/mediaQueries';
import ScrollIcon from '../../assets/images/Scroll.inline.svg';

const IntroSection = (): JSX.Element => {
  const mobileLayout = useMaxWidthBreakpoint(680);

  return (
    <Wrapper mobileLayout={mobileLayout}>
      <MainContentColumnWrapper>
        <TextBlock>
          <Upheader $color="delicateAccented">Careers</Upheader>
          <SectionTitle>
            Help us bring life-saving drugs to the clinic faster
          </SectionTitle>
          <SectionText>
            We’re pioneering the computational approach to antibody-based drug
            discovery. Join our team and develop innovative bioinformatics
            solutions that facilitate the process of engineering antibodies for
            therapeutic purposes. To help you excel and remove creativity
            blockers, we follow agile and other best practices of the tech
            industry.
          </SectionText>
          <ScrollLink $color="delicate" to="#open-positions">
            <ScrollIcon />
            Check open positions
          </ScrollLink>
        </TextBlock>
      </MainContentColumnWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ mobileLayout: boolean }>`
  ${({ mobileLayout }) =>
    mobileLayout
      ? css`
          background-image: url('${careersMobileBackground}');
        `
      : css`
          background-image: url('${careersBackground}');
        `}
  background-size: cover;
  background-blend-mode: overlay;
  background-position: center;
  position: relative;
  min-height: 860px;

  :before {
    content: '';
    position: absolute;
    width: 85%;
    background-color: ${({ theme }) => theme.colors.backgrounds.secondary};
    height: 10px;
    bottom: 0px;
  }

  ${forScreenAtMost680pxWide(css`
    min-height: 700px;

    :before {
      width: 100%;
    }
  `)}
`;

const TextBlock = styled.div`
  padding: 297px 0 374px 0;

  ${forScreenAtMost680pxWide(css`
    padding: 174px 0 0 0;
  `)}
`;

const SectionText = styled(TextMedium).attrs({ $color: 'delicate' })`
  max-width: 424px;
  margin: 0 0 32px;
`;

const SectionTitle = styled(Title).attrs({ $color: 'primaryAccented' })`
  max-width: 553px;
  margin: 7px 0 25px;
`;

export default IntroSection;
