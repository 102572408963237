import React from 'react';
import { FooterBoxContent } from '../components/layout/Footer';
import Layout from '../components/layout/Layout';
import IntroSection from '../pages-components/careers/IntroSection';
import MainSection from '../pages-components/careers/MainSection';
import OpenPositions from '../pages-components/careers/OpenPositions';

const CareersPage = (): JSX.Element => {
  return (
    <Layout
      description="Careers"
      footerBoxContent={FooterBoxContent.ContactInvitation}
      title="Careers"
    >
      <IntroSection />
      <MainSection />
      <OpenPositions openPositions={[]} />
    </Layout>
  );
};

export default CareersPage;
